<template>
  <div class="">
    <trac-loading v-if="isLoading" />

    <main class="ml-12" v-if="products && completedCountDetails">
      <trac-back-button>All Counts</trac-back-button>
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-2xl capitalize">
            {{ completedCountDetails.name }}
          </h3>
        </div>
        <div class="flex-col ">
          <div class="flex flex-row gap-5">
            <!-- <trac-button class="uppercase" @button-clicked="">Reject Count</trac-button> -->
            <trac-button class="uppercase" @button-clicked="recountData"
              >recount</trac-button
            >
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-5">
        <div class=" px-8 py-8 w-6/12 bg-white rounded-lg  big-shadow">
          <span class="font-semibold text-xl">Count Summary</span>
          <div class="flex flex-row justify-between mt-4">
            <ul class="flex-column">
              <li class=" text-xs text-primaryGray">Count name:</li>
              <li class=" text-xs capitalize">
                {{ completedCountDetails.name }}
              </li>
            </ul>
            <ul class="flex-col w-3/12">
              <li class=" text-xs text-primaryGray">
                Count categories:
              </li>
              <li class=" text-xs capitalize">{{ allCategories }}</li>
            </ul>
          </div>
          <div class="flex flex-row justify-between mt-5">
            <ul class="flex-column">
              <li class=" text-xs text-primaryGray">Location:</li>
              <li class=" text-xs">{{ completedCountDetails.location }}</li>
            </ul>
            <ul class="flex-col w-3/12">
              <li class=" text-xs text-primaryGray">
                Stock taking method:
              </li>
              <li class=" text-xs">N/A</li>
            </ul>
          </div>
          <div class="flex flex-row justify-between mt-5">
            <ul class="flex-column">
              <li class=" text-xs text-primaryGray">Date:</li>
              <li class=" text-xs">
                {{
                  moment(completedCountDetails.date).format("Do, DD MMM YYYY")
                }}
              </li>
              <li class=" text-xs">
                {{ moment(completedCountDetails.date).format("hh:mm A") }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class=" px-8 py-8 w-6/12 bg-white rounded-lg h-full overflow-hidden big-shadow"
        >
          <span class="font-semibold text-xl">Count Breakdown</span>
          <div class="bg-white border  mt-5 rounded-lg  overflow-auto h-64">
            <table class="w-full rounded-lg table-auto overflow-auto ">
              <thead class="bg-blue-100">
                <tr>
                  <th class="text-left p-4 text-xs font-semibold uppercase">
                    products
                  </th>
                  <th class="text-left p-4 text-xs font-semibold uppercase">
                    counted
                  </th>
                  <th class="text-left p-4 text-xs font-semibold uppercase">
                    expected
                  </th>
                  <th class="text-left p-4 text-xs font-semibold uppercase">
                    exceptions
                  </th>
                </tr>
              </thead>
              <tbody class="overflow-auto h-60">
                <tr
                  v-for="(product, index) in completedCountDetails.Products"
                  :key="index"
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                >
                  <td class="p-4 text-xs  capitalize">
                    {{ product.name }}
                  </td>
                  <td class="p-4 text-xs  capitalize">
                    {{ product.counted }}
                  </td>
                  <td class="p-4 text-xs  capitalize">
                    {{ product.expected }}
                  </td>
                  <td class="p-4 text-xs  capitalize">
                    {{ product.counted - product.expected }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import moment from "moment";
import { eventBus } from "./../../../main";

export default {
  name: "CountCompleted",
  data() {
    return {
      moment,
      completedCountDetails: null,
      isLoading: false,
      products: null,
      filteredProducts: null,
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchAllProducts();
    this.completedCountDetails = GET_LOCAL_DB_DATA("stock-count-details");
    this.completedCountDetails.Products = this.completedCountDetails.Products.map(
      (prod) => {
        return this.addCategoryToVariant(prod);
      }
    );

    this.isLoading = false;
  },
  computed: {
    allCategories() {
      return [
        ...new Set(
          this.completedCountDetails.Products.map((prod) => prod.category)
        ),
      ].join(", ");
    },
  },
  methods: {
    addCategoryToVariant(variant) {
      this.filteredProducts.forEach((product) => {
        if (product.variation) {
          product.varieties.forEach((v) => {
            if (v._id === variant.product_id) {
              // variant.category = product.category;
              variant = { ...variant, _id: variant.product_id, manage_stock: product.manage_stock, category: product.category };
            }
          });
        } else {
          variant = { ...variant, manage_stock: product.manage_stock };
        }
      });
      return variant;
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.products = res.items || [];
        this.filteredProducts = this.products;
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit('trac-alert', { message: res.message || "Error: Error with network." });
      }
    },
    recountData() {
      SAVE_LOCAL_DB_DATA("stock-count-data", {
        from: "Count Details",
        saved_counted_details: this.completedCountDetails,
        name: this.completedCountDetails.name,
        selectedStore: { name: this.completedCountDetails.location },
        selected_categories: this.allCategories.split(",").map((category) => {
          return { name: category };
        }),
      });

      this.$router.push({ name: "SubmitCount" });
    },
  },
};
</script>

<style scoped></style>
